import { useAxios } from '../../axiosUtil'

export const ntisUpdateAll = async (labNo) => {
  const result = await useAxios('/v1/ntis/update', {
    method: 'POST',
    data: { labNo }
  })

  return result
}
