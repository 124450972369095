// INFO: 연구실 Data 입력 화면 // TODO: // 1차: 연구실 정보에 등록된 책임연구자 기준으로 API 검색 // 2차: 연구실 정보에 여러명의 연구자를
등록 할수 있게 만들기

<script>
// import listData from '@/data/labs' // 더미데이터 들어있는 js
import { getLabList } from '@/api/admin/labs.js'
import { ntisUpdateAll } from '@/api/admin/ntis/ntisUpdateAll'
import LoadingVue from '@/components/ui/Loading.vue'
import { Page, Sort, GridComponent, ColumnsDirective, Toolbar, CommandColumn, Search } from '@syncfusion/ej2-vue-grids'

// INFO: 검색 기능
import Dropdown from '@/components/ui/Dropdown.vue'

export default {
  name: 'DataList',
  components: {
    'ejs-grid': GridComponent,
    'e-columns': ColumnsDirective,
    LoadingVue,
		// INFO: 검색 기능
    Dropdown
  },
  provide: {
    grid: [Page, Sort, Toolbar, CommandColumn, Search]
  },
  data() {
    return {
      isLoading: false,
      currentPath: this.$route.fullPath, // 현재 접속 경로
      pageSettings: { pageSize: 10 }, // 10개씩 잘라서 페이지네이션
      format: { type: 'dateTime', format: 'y/M/d' },
      toolbar: ['Search', 'Edit'],
      commands: [
        { buttonOption: { content: 'Detail', cssClass: 'primary-btn' } },
        { buttonOption: { content: 'Update', cssClass: 'btn-info' } }
      ],
      listData: [],
			// INFO: 검색 기능
      persistedData: [],
      searchConditions: [
				{value: 'all', text: '전체'},
        { value: 'labNo', text: '연구실번호' },
        { value: 'managerUserId', text: '관리자ID' },
        { value: 'labName', text: '연구실이름' },
        { value: 'leadResearcherName', text: '책임연구자' },
        { value: 'affiliatedInstitutionName', text: '대학교' }
      ],
      searchBy: ''
    }
  },
  methods: {
    commandClick(e) {
      if (e.target.classList.contains('primary-btn')) return this.labDetail(e)
      if (e.target.classList.contains('btn-info')) return this.labDataUpdate(e)
    },
    async labDataUpdate(e) {
      // TODO: 선택된 연구실의 Data를 업데이트 한다.
      const result = confirm(`${e.rowData.leadResearcherName} & ${e.rowData.affiliatedInstitutionName} 의 정보를 업데이트 하시겠습니까?`)
      if (result) {
        this.isLoading = true
        const result = await ntisUpdateAll(e.rowData.labNo)

        if (result.code) alert(result.message)
        else alert('업데이트 완료 하였습니다')

        this.isLoading = false
      } else {
        alert('취소 하였습니다')
      }
    },
    updateAll() {
      const result = confirm('전체 업데이트를 진행하시겠습니다.')
      if (result) {
        // TODO: 저장된 정보를 기반한 전체 업데이트 동작
        alert('업데이트 완료 하였습니다')
      } else {
        alert('취소 하였습니다')
      }
    },
    addLab() {
      this.$router.push({ path: `${this.currentPath}/w` })
    },
    labDetail(e) {
      this.$router.push({ path: `${this.currentPath}/w/${e.rowData.labNo}` })
    },
		// INFO: 검색 기능
    actionComplete(e) {
      if (e.requestType === 'searching') return this.searchInSyncfusionGrid(e)
    },
    searchInSyncfusionGrid(e) {
			if(this.searchBy === 'all') return
      this.listData = this.persistedData.filter((item) => item[this.searchBy]?.toString().includes(e.searchString) ?? true)
    },
    selectDropdownHandler(opt) {
      this.searchBy = opt.value
    },
    setSearchOptions() {
      const searchWrapper = this.$refs.grid.$el.querySelector('.e-toolbar-right')
      const dropdown = this.$refs.selectOption.$el
      searchWrapper.prepend(dropdown)

      searchWrapper.style.display = 'flex'
      searchWrapper.style.gap = '1em'
      dropdown.querySelector('button').style.border = 'none'
    }
  },
  mounted() {
		// INFO: 검색 기능
    this.setSearchOptions()
    const getList = async () => {
      this.isLoading = true

      const data = (await getLabList()).items

      if (!(data instanceof Array)) {
        return (this.isLoading = false)
      }

      this.listData = data
        .map((item) => {
          return {
            labNo: item.labNo,
            labName: item.labName,
            leadResearcherName: item.leadResearcherName,
            affiliatedInstitutionName: item.affiliatedInstitutionName,
            managerUserId: item.managerUserId,
            updatedAt: item.updatedAt
          }
        })
        .sort((a, b) => {
          if (a.labNo > b.labNo) return 1
          return -1
        })

			// INFO: 검색 기능
      this.persistedData = this.listData
      this.isLoading = false
    }
    getList()
  }
}
</script>

<style scoped>
.btn_area {
  /* text-align: right;
  margin: 10px 0px; */
  display: flex;
  margin-left: 10px;
  align-items: center;
  justify-content: flex-start;
}
</style>

<template>
  <LoadingVue v-if="isLoading" />
  <div class="btn_area">
    <!-- <b-button class="mx-1" variant="primary" @click="addLab()">추가하기</b-button> -->
    <!-- <b-button class="mx-1" variant="primary" @click="updateAll()">전체 업데이트 하기</b-button> -->
  </div>
	<!-- 검색기능 -->
  <Dropdown
    class="select-wrapper"
    :options="searchConditions"
    :disabled="false"
    name="zipcode"
    ref="selectOption"
    @selected="selectDropdownHandler"
  ></Dropdown>

  <div class="col-lg-12 control-section">
    <ejs-grid
      :dataSource="listData"
      :allowPaging="true"
      :allowSorting="true"
      :pageSettings="pageSettings"
      :filterSettings="filterOptions"
      :commandClick="commandClick"
      :toolbar="toolbar"


      :actionComplete="actionComplete"
      ref="grid"
    >
      <e-columns>
        <e-column field="labNo" headerText="연구실번호" width="120" textAlign="center" />
        <e-column field="managerUserId" headerText="관리자ID" width="130" textAlign="center" />
        <e-column field="labName" headerText="연구실이름" width="150" textAlign="center" />
        <e-column field="leadResearcherName" headerText="책임연구자" width="150" textAlign="center" />
        <e-column field="affiliatedInstitutionName" headerText="대학교" width="150" textAlign="center" />
        <e-column field="updatedAt" headerText="수정일" width="130" :format="format" type="date" textAlign="center" />
        <e-column field="manage" headerText="관리" width="150" textAlign="center" :commands="commands" />
      </e-columns>
    </ejs-grid>
  </div>
</template>
